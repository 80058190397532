import { t } from "@lingui/macro";
import {
  BudgetOrderByColumnEnum,
  BudgetTypeEnum,
  BudgetWhereColumn,
  BudgetsFilterOptionsDocument,
  BudgetsFilterOptionsQuery,
  BudgetsFilterOptionsQueryVariables,
  CreateQuoteDocument,
  CreateQuoteMutation,
  CreateQuoteMutationVariables,
  GetBudgetsDocument,
  GetBudgetsQuery,
  GetBudgetsQueryVariables,
  SqlOperator,
} from "@src/__generated__/graphql";
import { IOption } from "@src/components/ui-kit";
import { FetchHelper } from "@src/helpers/apollo/fetch";
import { MutationHelper } from "@src/helpers/apollo/mutation";
import { client } from "@src/services/client";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { Filter, Filters } from "@src/utils/components/filters/models";
import { OrderBy } from "@src/utils/components/sorting/OrderBy";
import { PaginationState } from "@src/utils/mobx/states/PaginationState";
import { action, computed, makeObservable, observable } from "mobx";
import Router from "next/router";
import { BudgetModel } from "../../projects/budgets/models/budget";

export const getTypeOptions = (): IOption[] => [
  { label: t`Project-synced`, value: BudgetTypeEnum.ActiveAssigned },
  { label: t`Proposals`, value: BudgetTypeEnum.Unassigned },
  { label: t`Archived`, value: BudgetTypeEnum.Archived },
];

export class BudgetsExportStore implements BaseStore {
  readonly tableKey = "budgets-export-listing";

  fetcher = new FetchHelper<GetBudgetsQuery, GetBudgetsQueryVariables>(
    GetBudgetsDocument,
  );

  @observable pagination = new PaginationState(this.tableKey, {
    onChangePagination: () => {
      this.fetchBudgets();
    },
  });

  @observable orderBy = new OrderBy<BudgetOrderByColumnEnum>([]);
  @observable tableData: BudgetModel[] = [];
  @observable tableAllIds: string[] = [];
  @observable selectedIds: Set<string> = new Set();

  createQuoteMutator = new MutationHelper<
    CreateQuoteMutation,
    CreateQuoteMutationVariables
  >(CreateQuoteDocument);

  @observable where = new Filters<BudgetWhereColumn>(
    [
      new Filter({
        title: t`Brand`,
        column: BudgetWhereColumn.BrandId,
        operator: SqlOperator.In,
        options: [],
      }),
      new Filter({
        title: t`Project`,
        column: BudgetWhereColumn.ProjectId,
        operator: SqlOperator.In,
        options: [],
      }),
      // new Filter({
      //   title: t`Account manager`,
      //   column: BudgetWhereColumn.AccountManager,
      //   operator: SqlOperator.In,
      //   options: [],
      // }),
      new Filter({
        title: t`Type`,
        hidden: true,
        column: BudgetWhereColumn.Type,
        operator: SqlOperator.In,
        options: getTypeOptions(),
      }),
      new Filter({
        title: t`Internally approved`,
        column: BudgetWhereColumn.InternallyApproved,
        operator: SqlOperator.Eq,
        options: [
          { label: t`Yes`, value: "true" },
          { label: t`No`, value: "false" },
        ],
      }),
      new Filter({
        title: t`Client approved`,
        column: BudgetWhereColumn.ClientApproved,
        operator: SqlOperator.Eq,
        options: [
          { label: t`Yes`, value: "true" },
          { label: t`No`, value: "false" },
        ],
      }),
      new Filter({
        title: t`Date`,
        column: BudgetWhereColumn.Date,
        operator: SqlOperator.Between,
        dateRange: true,
        options: [],
      }),
    ],
    { onChange: () => this.fetchBudgets() },
  );

  constructor(public appStore: AppStore) {
    makeObservable(this);
  }

  @computed get fetching(): boolean {
    return this.fetcher.isLoading.value;
  }

  @computed get returnParams() {
    return `where=${this.where.asURLSearchParam.where}`;
  }

  @action.bound public async fetchBudgets() {
    Router.replace({
      query: {
        where: this.where.asURLSearchParam.where,
        orderBy: this.orderBy.asURLSearchParam.orderBy,
        first: this.pagination.asURLSearchParam.first,
        page: this.pagination.asURLSearchParam.page,
      },
    });

    const [data, error] = await this.fetcher.fetch({
      filters: {
        where: this.where.asWhereParam,
        page: this.pagination.asParams.page,
        first: this.pagination.asParams.first,
        orderBy: this.orderBy.asWhereParam,
      },
    });

    if (!data || error) return;

    this.setTableData(data);
  }

  @action public async setFilterOptions() {
    const { data } = await client
      .query<
        BudgetsFilterOptionsQuery,
        BudgetsFilterOptionsQueryVariables
      >(BudgetsFilterOptionsDocument, {})
      .toPromise();

    if (!data) return;

    this.where.filtersByColumn.get(BudgetWhereColumn.BrandId)?.setOptions(
      data.brandsSimpleMap.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    );
    this.where.filtersByColumn.get(BudgetWhereColumn.ProjectId)?.setOptions(
      data.projectsSimpleMap.map(({ id, title, code }) => ({
        value: id,
        label: code + " " + title,
      })),
    );
  }

  @action private setTableData(data: GetBudgetsQuery): void {
    this.pagination.setFromPaginatorInfo(data.budgets.paginatorInfo);
    this.tableAllIds = data.budgets.allIds.map((id) => id.toString());

    if (!data.budgets.data) return;
    this.tableData = [];
    const budgetGroupCount = data.budgets.data.length ?? 0;

    for (let i = 0; i < budgetGroupCount; i++) {
      const budgets = data.budgets.data[i].budgets;
      const budgetsCount = budgets?.length ?? 0;

      if (budgetsCount === 0) continue;
      if (!budgets?.[0]) continue;
      const row = new BudgetModel({
        id: budgets[0].id,
        project_id: budgets[0].project_id,
        price: budgets[0].price,
        version: budgets[0].version,
        project: budgets[0].project,
        brand: budgets[0].brand,
        currency: budgets[0].currency,
        temporaryBrand: budgets[0].temporaryBrand,
        temporaryProject: budgets[0].temporaryProject,
        assignment_date: budgets[0].assignment_date,
        account_manager: budgets[0].account_manager,
        is_active: budgets[0].is_active,
        created_at: budgets[0].created_at,
        updated_at: budgets[0].updated_at,
        internally_approved: budgets[0].internally_approved,
        client_approved: budgets[0].client_approved,
        status: budgets[0].status,
        temporary_project_id: budgets[0].temporary_project_id,
        budget_name: data.budgets.data[i].budget_name,
        __subRows: [],
      });

      if (budgetsCount > 1) {
        for (let j = 0; j < budgetsCount; j++) {
          const budget = budgets[j];
          if (!budget) continue;

          row.__subRows?.push(new BudgetModel(budget));
        }
      }

      this.tableData.push(row);
    }
  }

  @action.bound handleSelect(ids: string[]) {
    this.selectedIds = new Set(ids);
  }

  @computed get budgetTypeFilter() {
    return this.where.filtersByColumn.get(BudgetWhereColumn.Type);
  }

  @action.bound async handleDeleteBudget(id: string) {
    await this.tableData.find((budget) => budget.id === id)?.handleDelete();
    this.fetchBudgets();
  }

  @action.bound async exportQuotes(ids: string[]) {
    const [data, error] = await this.createQuoteMutator.mutate({
      budget_ids: ids,
    });

    if (error || !data.createQuote) return;

    window.open(data.createQuote.url, "_blank");
  }
}
