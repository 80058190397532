import { t } from "@lingui/macro";
import {
  GetQuoteForSendingDocument,
  GetQuoteForSendingQuery,
  GetQuoteForSendingQueryVariables,
  Quote,
  QuoteSendInput,
  SendQuoteDocument,
  SendQuoteMutation,
  SendQuoteMutationVariables,
} from "@src/__generated__/graphql";
import {
  EMPTY_EDITOR_LENGTH,
  TextEditorRef,
} from "@src/components/ui-kit/TextEditor/TextEditor";
import { SendQuoteModal } from "@src/components/widgets/Modals/ModalSendQuote/SendQuoteModal";
import { FetchHelper } from "@src/helpers/apollo/fetch";
import { MutationHelper } from "@src/helpers/apollo/mutation";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { ModalStore } from "@src/stores/ModalStore";
import {
  email,
  minLength,
  multipleEmails,
  required,
} from "@src/utils/forms/validators";
import { DisclosureState } from "@src/utils/mobx/states/DisclosureState";
import { FieldState, FormState } from "formstate";
import { action, makeObservable, observable } from "mobx";
import { createRef } from "react";

type TAdditionalData = {
  id: Quote["id"];
  onSubmit?: () => void;
};

type TQuote = GetQuoteForSendingQuery["GetQuote"];

export class SendQuoteModalStore implements BaseStore, ModalStore {
  appStore: AppStore;
  readonly modalId = "sendQuoteModal";

  @observable quote: TQuote | null = null;

  contentEditorRef = createRef<TextEditorRef>();

  sendQuoteMutator = new MutationHelper<
    SendQuoteMutation,
    SendQuoteMutationVariables
  >(SendQuoteDocument);

  quoteFetcher = new FetchHelper<
    GetQuoteForSendingQuery,
    GetQuoteForSendingQueryVariables
  >(GetQuoteForSendingDocument);

  form = new FormState({
    recipients: new FieldState("")
      .validators(email, required)
      .setAutoValidationDebouncedMs(1000),
    otherRecipients: new FieldState("")
      .validators(multipleEmails)
      .setAutoValidationDebouncedMs(1000),
    subject: new FieldState<string | undefined>(""),
    content: new FieldState("")
      .validators(minLength(EMPTY_EDITOR_LENGTH - 1))
      .enableAutoValidation(),
  });

  @observable modalState = new DisclosureState<TAdditionalData>({
    onOpen: async () => {
      this.appStore.UIStore.dialogs.openModal({
        id: this.modalId,
        content: <SendQuoteModal />,
      });

      const quote = await this.fetchQuote();

      if (!quote) return;

      const projectName =
        quote.budgets[0].project?.title ??
        quote.budgets[0].temporaryProject?.name ??
        t`[Project Name]`;
      const clientName =
        quote.budgets[0].brand?.client?.name ??
        quote.budgets[0].brand?.contacts[0]?.email ??
        t`[Client Name]`;

      this.form.$.recipients.reset(
        quote.budgets[0].brand?.contacts[0]?.email ?? "",
      );
      this.form.$.subject.onChange(t`Quote Approval: ${projectName}`);

      this.contentEditorRef.current?.setContent(t`
        <p>Hello ${clientName},</p>
        <p>We've prepared the quote for ${projectName}, and you can review all the details by following the link below:<br/>
        View & Approve Quote<br/>
        Once approved, we can proceed with the next steps. If you have any questions or need any adjustments, feel free to reach out—we're happy to discuss anything further.<br/>
        Looking forward to your confirmation.</p>
        <p>Best regards,</p>
        <p>${this.appStore.authStore.user!.full_name}</p>
        <p>${this.appStore.workspaceStore.settings?.title ?? ""}</p>
      `);
    },
    onClose: () => {
      this.appStore.UIStore.dialogs.closeModal(this.modalId);
      this.modalState.additionalData = undefined;
      this.form.reset();
    },
  });

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
  }

  @action.bound async serialize(): Promise<QuoteSendInput | undefined> {
    const { hasError } = await this.form.validate();
    console.log(hasError);
    if (hasError) return;
    if (!this.modalState.additionalData?.id) return;

    return {
      id: this.modalState.additionalData.id,
      content: this.form.$.content.$.replaceAll("<p></p>", "<br/>"),
      subject: this.form.$.subject.$ ?? "",
      recipients: [this.form.$.recipients.$],
      other_recipients: this.form.$.otherRecipients.$.replaceAll(" ", "").split(
        ",",
      ),
    };
  }

  @action.bound async fetchQuote() {
    if (!this.modalState.additionalData?.id) return;
    const [data, error] = await this.quoteFetcher.fetch(
      {
        id: this.modalState.additionalData.id,
      },
      undefined,
    );

    if (error || !data.GetQuote) return;

    this.quote = data.GetQuote;
    return this.quote;
  }

  @action.bound async sendEmail() {
    const formData = await this.serialize();
    if (!formData) return;

    const [data, error] = await this.sendQuoteMutator.mutate({
      input: formData,
    });

    if (error || !data.sendQuote) return;

    this.appStore.UIStore.toast({
      title: t`Quote was successfully sent`,
      status: "success",
    });

    this.modalState.additionalData?.onSubmit?.();
    this.modalState.onClose();
  }
}
