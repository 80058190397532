import { t } from "@lingui/macro";
import {
  BudgetExportOptionsInput,
  BudgetPdfExportOurWorkPositionsEnum,
  BudgetPdfExportOurWorkViewEnum,
  BudgetPdfSettings,
  BudgetTypeEnum,
  GetQuoteDocument,
  GetQuoteQuery,
  GetQuoteQueryVariables,
  PreviewQuoteDocument,
  PreviewQuoteMutation,
  PreviewQuoteMutationVariables,
  SaveQuoteDocument,
  SaveQuoteMutation,
  SaveQuoteMutationVariables,
} from "@src/__generated__/graphql";
import { IOption } from "@src/components/ui-kit";
import { FetchHelper } from "@src/helpers/apollo/fetch";
import { MutationHelper } from "@src/helpers/apollo/mutation";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { toApiDate } from "@src/utils/dates";
import { BooleanState } from "@src/utils/mobx/states/BooleanState";
import { addDays } from "date-fns";
import { FieldState, FormState } from "formstate";
import { action, makeObservable, observable } from "mobx";
import { Prettify } from "ts-essentials";
export const getTypeOptions = (): IOption[] => [
  { label: t`Active assigned`, value: BudgetTypeEnum.ActiveAssigned },
  { label: t`Active unassigned`, value: BudgetTypeEnum.Assigned },
  { label: t`Proposals`, value: BudgetTypeEnum.Unassigned },
  { label: t`Archived`, value: BudgetTypeEnum.Archived },
];

type TFormKeys = Prettify<
  keyof NonNullable<
    (typeof EditQuotePageStore)["prototype"]["optionsForm"]
  >["$"]
>;

type Quote = GetQuoteQuery["GetQuote"];

export class EditQuotePageStore implements BaseStore {
  appStore: AppStore;

  @observable.ref quote: Quote | null = null;
  @observable.ref preview: {
    settings: BudgetExportOptionsInput;
    url: string;
  } | null = null;

  previewQuoteMutator = new MutationHelper<
    PreviewQuoteMutation,
    PreviewQuoteMutationVariables
  >(PreviewQuoteDocument);

  saveQuoteMutator = new MutationHelper<
    SaveQuoteMutation,
    SaveQuoteMutationVariables
  >(SaveQuoteDocument);

  quoteFetcher = new FetchHelper<GetQuoteQuery, GetQuoteQueryVariables>(
    GetQuoteDocument,
  );

  @observable.deep optionsForm: ReturnType<typeof this.getOptionsForm> | null =
    null;

  isLoadingSendQuote = new BooleanState(false);
  isDownloadingQuote = new BooleanState(false);

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
    this.optionsForm = this.getOptionsForm(
      this.appStore.workspaceStore.settings?.budget_pdf_export_settings,
    );
  }

  @action.bound async fetchQuote(id: string) {
    const [data, error] = await this.quoteFetcher.fetch(
      {
        id,
      },
      undefined,
    );

    if (error || !data.GetQuote) return;

    this.quote = data.GetQuote;
    this.preview = {
      settings: data.GetQuote.settings,
      url: data.GetQuote.url,
    };
  }

  @action.bound async refreshPreview() {
    if (!this.quote) return;
    const settings = this.serializeOptions();
    if (!settings) return;

    const [data, error] = await this.previewQuoteMutator.mutate({
      input: {
        id: this.quote.id,
        settings,
      },
    });

    if (error || !data.previewQuote) return;
    this.preview = {
      settings,
      url: data.previewQuote.url,
    };
  }

  @action getOptionsForm(defaultOptions: BudgetPdfSettings | undefined | null) {
    return new FormState({
      date: new FieldState(new Date()),
      valid_for: new FieldState("30"),
      valid_until: new FieldState(addDays(new Date(), 30)),
      show_disclaimer: new FieldState(defaultOptions?.show_disclaimer),
      signature: new FieldState(defaultOptions?.signature),
      show_summary: new FieldState(defaultOptions?.show_summary),
      show_client_name: new FieldState(defaultOptions?.show_client_name),
      show_client_details: new FieldState(defaultOptions?.show_client_details),
      disclaimer: new FieldState(defaultOptions?.disclaimer),
      our_work_positions: new FieldState(
        defaultOptions?.our_work_positions ??
          BudgetPdfExportOurWorkPositionsEnum.Columns,
      ),
      our_work_view: new FieldState(
        defaultOptions?.our_work_view ??
          BudgetPdfExportOurWorkViewEnum.Detailed,
      ),
      our_work_description: new FieldState(
        defaultOptions?.our_work_description,
      ),
      our_work_vat_in_items: new FieldState(
        defaultOptions?.our_work_vat_in_items,
      ),
      our_work_show_discount: new FieldState(
        defaultOptions?.our_work_show_discount,
      ),
      // TODO return grammatically correct field name from backend
      // eslint-disable-next-line @cspell/spellchecker
      expense_show_commission: new FieldState(
        defaultOptions?.expense_show_commision,
      ),
      expense_show_description: new FieldState(
        defaultOptions?.expense_show_description,
      ),
      expense_show_quantity: new FieldState(
        defaultOptions?.expense_show_quantity,
      ),
      expense_show_discount: new FieldState(
        defaultOptions?.expense_show_discount,
      ),
      expense_show_vat_in_items: new FieldState(
        defaultOptions?.expense_show_vat_in_items,
      ),
      save_for_workspace: new FieldState(false),
      language: new FieldState(defaultOptions?.language ?? "EN"),
      show_hours: new FieldState(
        typeof defaultOptions?.show_hours === "boolean"
          ? defaultOptions.show_hours
          : true,
      ),
    });
  }

  @action.bound async saveQuote() {
    if (!this.quote) return;
    const settings = this.serializeOptions();
    if (!settings) return;

    const [data, error] = await this.saveQuoteMutator.mutate({
      input: {
        id: this.quote.id,
        settings,
      },
    });

    if (error || !data.saveQuote) return null;
    return data.saveQuote;
  }

  @action.bound async sendQuote() {
    if (!this.quote) return;
    this.isLoadingSendQuote.on();
    await this.saveQuote();
    this.appStore.sendQuoteModalStore.modalState.onOpen({
      id: this.quote.id,
    });
    this.isLoadingSendQuote.off();
  }

  @action.bound serializeOptions(
    data: ReturnType<typeof this.getOptionsForm>["$"] | undefined = this
      .optionsForm?.$,
  ): BudgetExportOptionsInput | undefined {
    if (!data) return undefined;
    const options = new Map<TFormKeys, any>();
    const keysToOmit: Array<TFormKeys> = [
      "date",
      "valid_for",
      "valid_until",
      "disclaimer",
    ];

    Object.entries(data).forEach(([key, value]) => {
      if (keysToOmit.includes(key as TFormKeys)) return;
      options.set(key as TFormKeys, value.$ !== null ? value.$ : false);
    });

    options.set(
      "valid_until",
      toApiDate(addDays(data.date.$, Number(data.valid_for.$))),
    );

    options.set("date", toApiDate(data.date.$));

    if (data.show_disclaimer.$) {
      options.set("disclaimer", data.disclaimer.$ ?? "");
    }

    if (
      data.save_for_workspace.$ &&
      this.appStore.workspaceStore.settings?.budget_pdf_export_settings
    ) {
      this.appStore.workspaceStore.settings.budget_pdf_export_settings =
        Object.fromEntries(options);
    }

    return Object.fromEntries(options);
  }

  @action.bound async downloadQuote() {
    this.isDownloadingQuote.on();
    const quote = await this.saveQuote();
    this.isDownloadingQuote.off();

    if (!quote) return;
    window.open(quote.url, "_blank");
  }
}
