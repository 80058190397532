import {
  Box,
  Checkbox,
  HStack,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Table,
  TableContainer,
  TableContainerProps,
  Tbody,
  Text,
  Tfoot,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { runIfFn } from "@chakra-ui/utils";
import { t } from "@lingui/macro";
import { SortOrder } from "@src/__generated__/urql-graphql";
import { Icon } from "@src/components/ui-kit/Icon";
import { WrapComponent } from "@src/utils/components/WrapComponent";
import cs from "classnames";
import { observer } from "mobx-react-lite";
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useLocalStorage } from "react-use";
import s from "./Body.module.css";
import Cell from "./Cell";
import CollapseAllButton from "./CollapseAllButton";
import CollapseRowButton from "./CollapseRowButton";
import SelectionRow from "./SelectionRow";
import SortableHeaderCell from "./SortableHeaderCell";
import SubRowsGroup from "./SubRowsGroup";
import { TColumn, TFooterColumn, TRow } from "./types";
import { useCollapseRows } from "./useCollapseRows";
import { useSelectRows } from "./useSelectRows";

export type TableSelectable =
  | boolean
  | "subrows"
  | "subrowsOrEmptyParent"
  | "subrowsOrParent";

interface SelectionProps<
  Row extends TRow<SubRow>,
  SubRow extends { id: string },
> {
  selectable?: TableSelectable;

  selected?: Row["id"][];
  onSelect?: (ids: Row["id"][]) => void;

  /** All row ids across the all pages */
  allIds?: Row["id"][];
}

interface SortableProps<OrderByCol> {
  orderedBy?: { column: OrderByCol; order: SortOrder };
  onChangeOrderBy?: (orderedBy: {
    column: OrderByCol;
    order: SortOrder;
  }) => void;
}

export interface CollapsibleProps<
  Row extends TRow<SubRow>,
  SubRow extends { id: string },
> {
  /** Open subrow on mount */
  subRowsVisible?: boolean;
  subRowsGroupBy?: keyof SubRow;
  subRowsGroupLabel?: (group: string, row: Row) => ReactNode;
  subRowsHideSelectCell?: boolean;
  subRowsHideCollapseCell?: boolean;
}

type BodyProps<
  Row extends TRow<SubRow>,
  OrderByCol,
  SubRow extends { id: string },
  Props,
> = {
  tableKey: string;
  data: Row[];
  columns: TColumn<Row, OrderByCol, SubRow, Props>[];
  customTBody?: React.ReactNode;
  totalsInFooter?: (TFooterColumn<Row[]> | undefined | null)[];
  columnVisibilityControl?: boolean;
  showOnlySubRows?: boolean;
  tableContainerSx?: TableContainerProps["sx"];
  beforeVisibilityControl?: ReactElement;

  /**
   * The way to inject custom props
   */
  props?: Props;
} & SelectionProps<Row, SubRow> &
  SortableProps<OrderByCol> &
  CollapsibleProps<Row, SubRow>;

export const DISABLED_COLUMNS_CHANGED_EVENT = "disabled-columns-changed";
const Body = <R extends TRow<S>, O, S extends { id: string }, P>({
  tableKey,
  data,
  columns,
  customTBody,
  totalsInFooter,
  props,
  selectable,
  selected = [],
  onSelect,
  allIds,
  orderedBy,
  onChangeOrderBy,
  subRowsVisible,
  subRowsGroupBy,
  subRowsGroupLabel,
  subRowsHideSelectCell,
  subRowsHideCollapseCell,
  showOnlySubRows,
  columnVisibilityControl = true,
  tableContainerSx,
  beforeVisibilityControl,
}: BodyProps<R, O, S, P>) => {
  const localStorageKey = `table_${tableKey}_disabled-cols`;
  const [disabledCols, setDisabledCols] = useLocalStorage<string[]>(
    localStorageKey,
    [],
  );

  useEffect(() => {
    window.addEventListener(DISABLED_COLUMNS_CHANGED_EVENT, handleColsChange);

    return () => {
      window.removeEventListener(
        DISABLED_COLUMNS_CHANGED_EVENT,
        handleColsChange,
      );
    };
  }, []);

  const handleColsChange = useCallback(() => {
    const cols = localStorage.getItem(localStorageKey);

    if (!cols) return;
    try {
      const parsedCols = JSON.parse(cols);
      if (!Array.isArray(parsedCols)) return;
      setDisabledCols(parsedCols);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const {
    isPageSelected,
    isPageIndeterminate,
    isRowSelected,
    isRowIndeterminate,
    onRowSelect,
    onPageSelect,
  } = useSelectRows(data, selected, {
    onSelect,
    selectable: selectable,
    subRowsMode: selectable === "subrows" || selectable === "subrowsOrParent",
  });

  const [collapsible, { isRowOpen, onCollapseRow, onCollapseAllRows }] =
    useCollapseRows(data, {
      subRowsVisible,
    });

  const { columnsToDisplay, columnHeaderToDisplay, columnsToControl } =
    useMemo(() => {
      const _columnsToDisplay = columns.filter((col) => {
        if (runIfFn(col.hide, props)) return false;
        return !disabledCols?.includes(col.key);
      });

      const _columnHeaderToDisplay = _columnsToDisplay.filter(
        (col) => col.key !== "actions",
      );

      const _columnsToControl = columns.filter(
        (col) => runIfFn(col.disableVisibilityControl) !== true,
      );

      return {
        columnsToDisplay: _columnsToDisplay,
        columnHeaderToDisplay: _columnHeaderToDisplay,
        columnsToControl: _columnsToControl,
      };
    }, [columns, disabledCols]);

  return (
    <TableContainer sx={tableContainerSx}>
      <Table className={cs("Polaris-DataTable__Table", s.table)}>
        <Thead>
          <Tr bg="gray.50">
            {selectable && (
              <Cell type="th" width={34}>
                <Checkbox
                  id="page"
                  isChecked={isPageSelected}
                  isIndeterminate={isPageIndeterminate}
                  onChange={(e) => onPageSelect(e.target.checked)}
                />
              </Cell>
            )}
            {collapsible && !showOnlySubRows && (
              <Cell type="th" width={34}>
                <CollapseAllButton onClick={onCollapseAllRows} />
              </Cell>
            )}
            {columnHeaderToDisplay.map((col) => (
              <SortableHeaderCell
                key={col.key}
                align={col.align}
                width={col.width}
                sortable={!!col.sortable}
                sortOrder={
                  orderedBy && orderedBy.column === col.sortable
                    ? orderedBy.order
                    : undefined
                }
                onClick={(order) => {
                  onChangeOrderBy?.({ column: col.sortable!, order });
                }}
                tooltip={col.headerTooltip}
              >
                {runIfFn(col.header, props)}
              </SortableHeaderCell>
            ))}
            {columnVisibilityControl && (
              <Cell type="th" width={64}>
                <Popover placement="bottom-end">
                  <WrapComponent
                    if={Boolean(beforeVisibilityControl)}
                    with={(children) => (
                      <HStack>
                        {beforeVisibilityControl}
                        {children}
                      </HStack>
                    )}
                  >
                    <PopoverTrigger>
                      <IconButton
                        aria-label={t`toggle visible columns`}
                        colorScheme="grey"
                        icon={
                          <Icon transform="auto" rotate={90} name="menu-01" />
                        }
                        variant="ghost"
                      />
                    </PopoverTrigger>
                  </WrapComponent>
                  <PopoverContent w="264px">
                    <PopoverBody px="5" py="4">
                      <Text
                        mb="4"
                        color="grey.800"
                        fontSize="15px"
                        fontWeight="medium"
                      >{t`Table columns`}</Text>
                      <Stack spacing="4">
                        {columnsToControl.map((col) => (
                          <Checkbox
                            key={col.key}
                            isChecked={!disabledCols?.includes(col.key)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setDisabledCols(
                                  disabledCols?.filter((i) => i !== col.key),
                                );
                              } else {
                                setDisabledCols([
                                  ...(disabledCols ?? []),
                                  col.key,
                                ]);
                              }
                              window.dispatchEvent(
                                new Event(DISABLED_COLUMNS_CHANGED_EVENT),
                              );
                            }}
                          >
                            <Box as="span" fontSize="15px!" fontWeight="normal">
                              {runIfFn(col.header, props)}
                            </Box>
                          </Checkbox>
                        ))}
                      </Stack>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Cell>
            )}
          </Tr>
        </Thead>

        <Tbody>
          {customTBody ? (
            customTBody
          ) : (
            <React.Fragment>
              {!!selected.length && allIds && (
                <SelectionRow
                  colSpan={
                    columnsToDisplay.length + +!!selectable + +collapsible
                  }
                  selectedIds={selected}
                  allIds={allIds}
                  onSelect={onSelect}
                />
              )}

              {data.map((row) => (
                <React.Fragment key={row.id}>
                  {!showOnlySubRows && (
                    <Tr
                      className={cs(
                        "Polaris-DataTable__TableRow",
                        {
                          "Polaris-DataTable__TableRow--pinned": row.is_pinned,
                        },
                        {
                          "Polaris-DataTable__TableRow--collapsible":
                            !!row.__subRows?.length,
                        },
                      )}
                      role="group"
                    >
                      {selectable && (
                        <Cell type="td" width={34}>
                          <Checkbox
                            id={row.id}
                            isChecked={isRowSelected(row.id, row.__subRows)}
                            isIndeterminate={isRowIndeterminate(row.__subRows)}
                            onChange={(e) => {
                              switch (selectable) {
                                case "subrowsOrParent":
                                  const idsToSelect = [row.id];
                                  row.__subRows?.forEach((subRow) => {
                                    idsToSelect.push(subRow.id);
                                  });
                                  onRowSelect(e.target.checked, idsToSelect);
                                  break;
                                case "subrowsOrEmptyParent":
                                  onRowSelect(e.target.checked, row.id);
                                  break;
                                case "subrows":
                                  onRowSelect(
                                    e.target.checked,
                                    row.__subRows?.map(({ id }) => id),
                                  );
                                  break;
                                default:
                                  onRowSelect(e.target.checked, row.id);
                                  break;
                              }
                            }}
                          />
                        </Cell>
                      )}

                      {collapsible && (
                        <Cell type="td" width={46}>
                          {!!row.__subRows?.length && (
                            <CollapseRowButton
                              open={isRowOpen(row.id)}
                              onClick={() => onCollapseRow(row.id)}
                            />
                          )}
                        </Cell>
                      )}

                      {columnsToDisplay.map((col) => (
                        <Cell
                          key={col.key}
                          type="td"
                          align={col.align}
                          width={col.width}
                          backgroundColor={col.backgroundColor}
                          padding={col.padding}
                        >
                          {col.render ? col.render(row, props) : row[col.key]}
                        </Cell>
                      ))}
                    </Tr>
                  )}

                  {isRowOpen(row.id) &&
                    (subRowsGroupBy !== undefined ? (
                      <SubRowsGroup
                        row={row}
                        columns={columnsToDisplay}
                        groupBy={subRowsGroupBy}
                        groupLabel={subRowsGroupLabel}
                        selectable={false}
                        hideSelectCell={subRowsHideSelectCell}
                        hideCollapseCell={subRowsHideCollapseCell}
                      />
                    ) : (
                      row.__subRows?.map((subRow) => (
                        <Tr
                          className="Polaris-DataTable__TableRow Polaris-DataTable__TableSubRow"
                          key={subRow.id}
                        >
                          {(selectable === true && <Cell type="td" />) ||
                            (selectable && (
                              <Cell type="td" width={34}>
                                <Checkbox
                                  id={subRow.id}
                                  isChecked={isRowSelected(subRow.id)}
                                  onChange={(e) => {
                                    switch (selectable) {
                                      case "subrowsOrParent":
                                        onRowSelect(
                                          e.target.checked,
                                          e.target.checked
                                            ? subRow.id
                                            : [row.id, subRow.id],
                                        );

                                        break;
                                      default:
                                        onRowSelect(
                                          e.target.checked,
                                          subRow.id,
                                        );
                                        break;
                                    }
                                  }}
                                />
                              </Cell>
                            ))}
                          {collapsible && !showOnlySubRows && (
                            <Cell type="td" />
                          )}
                          {columnsToDisplay.map((col) => (
                            <Cell
                              key={col.key}
                              type="td"
                              align={col.align}
                              width={col.width}
                              backgroundColor={col.backgroundColor}
                              colSpan={col.subRowColSpan}
                            >
                              {col.renderSubRow &&
                                col.renderSubRow(subRow, row, props)}
                            </Cell>
                          ))}
                        </Tr>
                      ))
                    ))}
                </React.Fragment>
              ))}
            </React.Fragment>
          )}
        </Tbody>
        <Tfoot>
          {totalsInFooter && (
            <Tr>
              {columnsToDisplay.map((col, idx) => {
                const footerCell = totalsInFooter[idx];

                return (
                  <Cell
                    key={col.key}
                    type="td"
                    align={footerCell?.align ?? col.align}
                    width={col.width}
                    className="bg-skyLighter"
                  >
                    {footerCell?.render(data)}
                  </Cell>
                );
              })}
            </Tr>
          )}
        </Tfoot>
      </Table>
    </TableContainer>
  );
};

export default observer(Body);
